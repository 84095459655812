<template>
  <div>
  

    <b-card class="text-center">

       <!-- error handelr -->
    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
      variant="danger">
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">{{ value }}</li>
        </ul>
      </div>
    </b-alert>

      <div class="row">
        <b-col cols="3"></b-col> 
        <b-col cols="6">
        <b>Choose Model Type <span class="text-danger">*</span> </b>
          <b-form-group class="mt-2" label="Model Type" label-for="Model Type">
            <b-form-select v-model="FilterForm.ModelType"  :options="ModelTypeOptions"></b-form-select>
          </b-form-group>
        </b-col>
      </div>

      <div class="row">
      </div>
      <div class="row">
        <b-col cols="12" class="mt-2">
          <b>Filter By Model Register</b>
        </b-col>

        <b-col cols="6">
          <b-form-group label="Start Date" label-for="start date">
            <flat-pickr v-model="FilterForm.startDate" class="form-control" :config="{
              dateFormat: 'Y-m-d H:i',
              minDate: '2021-01',
            }" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="End Date" label-for="End date">
            <flat-pickr v-model="FilterForm.endDate" :disabled="FilterForm.startDate == null ||
              FilterForm.startDate == ''
              " class="form-control" :config="{
          dateFormat: 'Y-m-d H:i',
          minDate: FilterForm.startDate,
        }" />
          </b-form-group>
        </b-col>


        <b-col cols="6"  v-if="FilterForm.ModelType == 'users'">
          <b-form-group label="Gender" label-for="gender">
            <b-form-select v-model="FilterForm.gender" :options="options"></b-form-select>
          </b-form-group>
        </b-col>


        <b-col cols="6" v-if="FilterForm.ModelType == 'users'">
          <b-form-group label="Users Category" label-for="Users Category">
            <b-form-select v-model="FilterForm.UserCategory"  :options="UserCategoryOptions"></b-form-select>
          </b-form-group>
        </b-col>



        <b-col cols="12" class="mt-2">
          <b-button variant="primary" :disabled="disabledFilter" @click="filter()">submit</b-button>
        </b-col>

        <b-col  cols="6" class="mt-2">
         <h3>  <span class="text-success" v-b-tooltip.hover title=" You must have at least one user"> TARGET USERS:    
        </span> {{ usersCount }} </h3> 

         <h3 >  <span class="text-success"> COST:</span>   
          <span  v-if="publishForm.type == 'email'">
                {{this.publishForm.model_ids.length *  0.01 }} 
         </span> 

         <span v-if="publishForm.type == 'sms'">
                {{this.publishForm.model_ids.length *  0.25 }} 
         </span> 
         EGP
        </h3> 
        </b-col>

        <b-col  cols="2" class="mt-2">
          <div class="text-bold">
            <b-form-group label="Newsletters type" v-slot="{ ariaDescribedby }">
              <b-form-radio v-model="publishForm.type" :aria-describedby="ariaDescribedby" name="some-radios" @change="changeType()" value="email">Email</b-form-radio>
              <b-form-radio v-model="publishForm.type" :aria-describedby="ariaDescribedby" name="some-radios" @change="changeType()" value="sms">SMS</b-form-radio>
             </b-form-group>
          </div>
      </b-col >

      </div>

    </b-card>

    <b-card>
          

        
          <validation-observer ref="simpleRules">
            <b-row>
          <b-col cols="12" class="text-center">
                  <h3 class="text-primary"> {{ publishForm.type }} Data</h3>
          </b-col>
            <b-col cols="12"  v-if="publishForm.type == 'email'"> 
              <b-form-group label="Title" label-for="Users Category">
                <validation-provider #default="{ errors }" name="title" rules="required">
                <b-form-input  v-model="publishForm.title" :id="'title'" :state="errors.length > 0 ? false:null" ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12" v-if="publishForm.type == 'email'">
                <b-form-group  label="Body" label-for="body">
                    <validation-provider #default="{ errors }" name="body" rules="required">
                        <vue-editor :id="'body'" v-model="publishForm.body" :state="errors.length > 0 ? false:null" rows="2" ></vue-editor>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
     

           <b-col md="12" v-if="publishForm.type == 'sms'">
              <b-form-group  label="Message Body" label-for="body">
                <validation-provider #default="{ errors }" name="body" rules="required">
                <b-form-textarea
                  id="body"
                  v-model="publishForm.body"
                  placeholder="Only 140 characters are allowed..."
                  rows="3"
                  :state="publishForm.body.length <= 140"
                  max-rows="6"
                ></b-form-textarea>
                <small class="text-danger" v-show="errors[0] != 'The title field is required'" >{{ errors[0] }}</small> 
              </validation-provider>

              </b-form-group>
          </b-col> 

          <b-col cols="12" class="text-center">
            <b-button variant="primary" :disabled="disabledFilter || (publishForm.type == 'sms' &&   publishForm.body.length > 140)  || this.publishForm.model_ids.length == 0"  @click="SendPublish()">Send {{ publishForm.type }}</b-button>
          </b-col>

        </b-row>
      </validation-observer>
    </b-card>



  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { VueEditor } from 'vue2-editor'
import { required } from '@validations'

export default {
  name: 'Users-Newsletters',
  components: {
    flatPickr,
    VueEditor,

  },
  data() {

    return {

      ModelTypeOptions: [
        { value: 'users', text: 'Users' },
        { value: 'admins', text: 'Admins' },
        { value: 'vendors', text: 'Sellers' },
      ],

      FilterForm: {
        startDate: null,
        endDate: null,
        gender: 'all',
        UserCategory: null,
        ModelType: 'users',
      },
      publishForm: {
        title: '',
        body: '',
        model_ids: [],
        type:  'email',
        model_type: '',
      },

      options: [
        { value: 'all', text: 'all' },
        { value: 'male', text: 'male' },
        { value: 'female', text: 'female' },
      ],


      UserCategoryOptions: [
        { value: 'buy_product', text: 'Buy products' },
        { value: 'not_complete_purchase', text: 'Did not complete the purchase' },
        { value: 'Joined_month', text: 'Joined a month ago' },
      ],

      usersCount: 0,
      showDismissibleAlert: false,
      errors_back: [],
      required,

      disabledFilter: false,
      disabledPublish: false,

    };
  },

  mounted() {

  },

  methods: {
    filter() {
      this.disabledFilter = true;
      let {FilterForm} = this;
      axios
        .post('/newsletters/filter', FilterForm)
        .then((result) => {
          this.usersCount = result.data.data.model_counts;
          this.publishForm.model_ids = result.data.data.model_ids;
          this.disabledFilter = false;
        })
        .catch((err) => {
          this.disabledFilter = false;
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        });
    },

    SendPublish()
    {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
      this.disabledPublish = true; 
      let {publishForm,FilterForm } = this;
      publishForm['filter_form'] = FilterForm;
      publishForm['model_type']  = FilterForm.ModelType;
      axios
        .post('/newsletters/publish', publishForm)
        .then((result) => {
          this.disabledPublish = false; 


          this.$swal({
                position: "center",
                icon: "success",
                title: "The Newsletters has been sent successfully",
                showConfirmButton: false,
                timer: 1500,
              });

              
          this.$router.push({
                path: "/newsletters/index",
              });
        })
        .catch((err) => {
          this.disabledPublish = false; 
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            this.$bvToast.toast(
            'Something went wrong' ,
                {
                  title: 'Error',
                  solid: true,
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-right',
                }
              );
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        });
      } 
    });
    },


    changeType(){
      this.publishForm.title = '';
      this.publishForm.body = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.text-bold{
  font-weight: bold;
}
</style>